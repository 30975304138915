import { createRouter, createWebHistory } from "vue-router"
const PasswordChange = () => import("@/components/Admin/PasswordChange/PasswordChange")
const AdminLogin = () => import("@/components/Admin/Login/AdminLogin")
const MapObjectViewerEditor = () => import("@/components/Admin/MapObjectManager/MapObjectViewerEditor")
const MapObjectsManager = () => import("@/components/Admin/MapObjectManager/MapObjectsManager")
const Landing = () => import("@/components/Landing/Landing")
const RegionMap = () => import("@/components/Map/RegionMap")
const AdminPage = () => import("@/components/Admin/AdminPage")
const AddNewObject = () => import("@/components/Admin/CreateNewObject/CreateNewObject")
const ThematicManager = () => import("@/components/Admin/ThematicManager/ThematicManager")
const MapObjectsRequests = () => import("@/components/Admin/MapObjectReview/MapObjectsRequests")
const MapObjectReview = () => import("@/components/Admin/MapObjectReview/MapObjectReview")
const NotFoundPage = () => import("@/components/Error/NotFoundPage")

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Онлайн карты РГО',
            component: Landing
        },
        {
            path: '/map/:id',
            name: 'Онлайн карта',
            component: RegionMap
        },
        {
            path: '/admin',
            name: 'Панель администратора',
            component: AdminPage,
        },
        {
            path: '/admin',
            name: 'Панель администратора',
            component: AdminPage,
            children: [
                {
                    path: 'map-object/requests',
                    name: "Рассмотрение заявок",
                    props: (route) => ({ action: route.query.action }),
                    component: MapObjectsRequests
                },
                {
                    path: 'map-object/review/:id',
                    name: "Рассмотрение заявки",
                    component: MapObjectReview,
                    props: true
                },
                {
                    path: 'map-object/add',
                    name: "Добавить новый объект",
                    component: AddNewObject
                },
                {
                    path: 'map-object/manager',
                    name: "Управление объектами",
                    props: (route) => ({ action: route.query.action }),
                    component: MapObjectsManager
                },
                {
                    path: 'map-object/edit/:id',
                    name: "Редактирование объекта",
                    component: MapObjectViewerEditor,
                    props: true
                },
                {
                    path: 'thematic/manager',
                    name: "Управление тематиками",
                    component: ThematicManager
                },
                {
                    path: 'password/change',
                    name: "Изменить пароль",
                    component: PasswordChange
                },
            ]
        },
        {
            path: '/admin/login',
            name: "Авторизация",
            component: AdminLogin
        },
        {
            path: '/:catchAll(.*)',
            name: 'Страница не найдена :(',
            component: NotFoundPage
        },
    ]
})

router.beforeEach((to, from, next) => {
    document.title = to.name;
    next();
});

export default router