<template>
    <router-view></router-view>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './assets/style/main.css'

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
    body {
        background: #fff;
    }
    input,
    textarea,
    select {
        padding: 0.4rem 0.6rem;
        border: 0.1rem solid #dee2e6;
        border-radius: 0.3rem;
        width: 100%;
    }
    a {
        text-decoration: none;
        color: inherit;
    }
    a:hover {
        color: inherit;
    }
    .box {
        background: #ffffff;
        padding: 1rem 2rem;

        border-radius: 1rem;
        margin-bottom: 1.5rem;

        box-shadow: rgba(17, 17, 26, 0.05) 0 0.25rem 1rem, rgba(17, 17, 26, 0.05) 0 0.5rem 2rem;
    }
</style>
